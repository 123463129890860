import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "../store";
import CDV from "@/Libary/Cordova/CDV";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Boot',
    component: () => import('@/views/App/Boot.vue'),
    meta: {

      hideHeader:true,
      hideFooter:true,
      blockSetPage: true,
      noMoreOpen:true
    }
  },
  {
    path: '/intro/',
    name: 'Intro',
    component: () => import('@/views/App/Intro.vue'),
    meta: {
      moduleKey:"app.intro",
      hideHeader  : true,
      hideFooter  : true,
      blockSetPage: true,
      noMoreOpen:true
    }
  },
  {
    path: '/site/:siteId/intro/:table?/:type?',
    name: 'SiteIntro',
    component: () => import('@/views/App/Intro.vue'),
    meta: {
      moduleKey:"app.intro",
      hideHeader  : true,
      hideFooter  : true,
      blockSetPage: true,
      noMoreOpen:true
    }
  },
  {
    path: '/select-site',
    name: 'Select Site',
    component:() => import('@/views/Pubs/SelectSite.vue'),
    meta:{
      transitionName:"slideV",
      depth:0,
      hideHeader: true,
      hideFooter: true,
      noMoreOpen:true
    }
  },
  {
    path: '/site/:siteId/tutorials',
    name: 'Tutorials',
    component: () => import('@/views/Tutorials/Tutorials.vue'),
    meta: {
      moduleKey:"app.tutorials",
      blockSetPage: true,
      transitionName:"slide",
      depth:10,

    }
  },
  {
    path: '/site/:siteId/tutorial/:tutorialId',
    name: 'Tutorial',
    component: () => import('@/views/Tutorials/Tutorial.vue'),
    meta: {
      moduleKey:"app.tutorials",
      blockSetPage: true,
      transitionName:"slide",
      depth:20,
      showBack: true
    }
  },
  {
    path: '/site/:siteId/tours',
    name: 'Tours',
    component: () => import('@/views/Tours/Tours.vue'),
    meta: {
      moduleKey:"app.tours",
      blockSetPage: true,
      transitionName:"slide",
      depth:10,

    }
  },
  {
    path: '/site/:siteId/tour/:tourId',
    name: 'Tour',
    component: () => import('@/views/Tours/Tour.vue'),
    meta: {
      moduleKey:"app.tours",
      blockSetPage: true,
      transitionName:"slide",
      depth:20,
      showBack: true
    }
  },
  {
    path: '/login/options',
    name: 'LoginOptions',
    component:  () => import('@/views/Auth/LoginOptions.vue'),
    meta: {
      moduleKey:"app.account",
      noMoreOpen:true
    }
  },
  {
    path: '/site/:siteId/login/:order?',
    name: 'Login',
    component:  () => import('@/views/Auth/Login.vue'),
    meta: {
      moduleKey:"app.account.login",
      noMoreOpen:true,
      showBack: true,
      blockSetPage: true
    }
  },
  {
    path: '/site/:siteId/forgotten-password',
    name: 'ForgottenPassword',
    component:  () => import('@/views/Auth/Forgotten.vue'),
    meta: {
      moduleKey:"app.account.login",
      blockSetPage: true,
      showBack:true,
      noMoreOpen:true,
    }
  },
  {
    path: '/password/reset/:code',
    name: 'ResetPassword',
    component:  () => import('@/views/Auth/ResetPassword.vue'),
    meta: {
      moduleKey:"app.account.login",
      blockSetPage: true,
      showBack:false,
      noMoreOpen:true,
      hideBasket:true
    }
  },
  {
    path: '/site/:siteId/password/reset/:code',
    name: 'ResetPasswordSite',
    component:  () => import('@/views/Auth/ResetPassword.vue'),
    meta: {
      moduleKey:"app.account.login",
      blockSetPage: true,
      showBack:false,
      noMoreOpen:true,
    }
  },
  {
    path: '/site/:siteId/register/:order?',
    name: 'Register',
    component: () => import('@/views/Auth/Register.vue'),
    meta: {
      blockSetPage: true,
      moduleKey:"app.account.register",
      showBack:true
    }
  },
  {
    path: '/site/:siteId/account',
    name: 'Account',
    component:() => import('@/views/Account/Account.vue'),
    meta:{
      moduleKey:"app.account",
      transitionName:"slideV",
      depth:10,
      authPage:true,
      blockSetPage: true
    }
  },
  {
    path: '/site/:siteId/account/details',
    name: 'My Details',
    component:() => import('@/views/Account/Details.vue'),
    meta: {
      blockSetPage: true,
      moduleKey:"app.account",
      showBack:true,
      authPage:true
    }
  },
  {
    path: '/site/:siteId/account/details/edit',
    name: 'Edit My Details',
    component:() => import('@/views/Account/EditDetails.vue'),
    meta: {
      blockSetPage: true,
      moduleKey:"app.account",
      showBack:true,
      authPage:true
    }
  },
  {
    path: '/site/:siteId/account/details/edit-login',
    name: 'Edit My Login Details',
    component:() => import('@/views/Account/EditLogin.vue'),
    meta: {
      blockSetPage: true,
      moduleKey:"app.account",
      showBack:true,
      authPage:true
    }
  },
  {
    path: '/site/:siteId/account/loyalty/cards',
    name: 'Loyalty Cards',
    component:() => import('@/views/Account/Loyalty/Cards.vue'),
    meta:{
      moduleKey:"app.account.wallet",
      transitionName:"slide",
      depth:110,
      showBack:true,
      blockSetPage: true,
      authPage:true,
      noMoreOpen:true
    }
  },
  {
    path: '/site/:siteId/account/loyalty/card/lite',
    name: 'Loyalty Card',
    component:() => import('@/views/Account/Loyalty/LiteCard.vue'),
    meta:{
      moduleKey:"app.account.wallet",
      transitionName:"slide",
      depth:110,
      showBack:true,
      hideFooter:true,
      blockSetPage: true,
      authPage:true,
      noMoreOpen:true
    }
  },
  {

    path: '/site/:siteId/account/loyalty/deposits',
    name: 'Deposit Cards',
    component:() => import('@/views/Account/Loyalty/Deposits.vue'),
    meta: {
      blockSetPage: true,
      moduleKey:"app.account.wallet",
      showBack:true,
      authPage:true
    }
  },
  {
    path: '/site/:siteId/account/loyalty/card/history/:brandId',///?:cardType
    name: 'Card History',
    component:() => import('@/views/Account/Loyalty/History.vue'),
    meta: {
      blockSetPage: true,
      moduleKey:"app.account.wallet",
      showBack:true,
      authPage:true
    }
  },

  {
    path: '/site/:siteId/account/loyalty/card/check/:cardType/:checkID',
    name: 'Card Check',
    component:() => import('@/views/Account/Loyalty/Check.vue'),
    meta: {
      blockSetPage: true,
      moduleKey:"app.account.wallet",
      showBack:true,
      authPage:true
    }
  },
  {
    path: '/site/:siteId/account/orders',
    name: 'My Orders',
    component:() => import('@/views/Account/Orders.vue'),
    meta:{
      moduleKey:"app.account.orders",
      blockSetPage: true,
      transitionName:"slide",
      depth:110,
      showBack:true,
      authPage:true
    }
  },
  {
    path: '/site/:siteId/account/bookings',
    name: 'My Bookings',
    component:() => import('@/views/Account/Bookings.vue'),
    meta:{
      moduleKey:"app.account",
      blockSetPage: true,
      transitionName:"slide",
      depth:110,
      showBack:true,
      authPage:true
    }
  },
  {
    path: '/site/:siteId/account/transfer-points',
    name: 'Transfer Points',
    component:() => import('@/views/Account/TransferPoints.vue'),
    meta:{
      blockSetPage: true,
      moduleKey:"app.account.transfer",
      transitionName:"slide",
      depth:110,
      showBack:true,
      authPage:true
    }
  },
  {
    path: '/site/:siteId/account/redeem-points',
    name: 'Redeem Points',
    component:() => import('@/views/Account/RedeemPoints.vue'),
    meta:{
      blockSetPage: true,
      moduleKey:"app.account.redeem",
      transitionName:"slide",
      depth:110,
      showBack:true,
      authPage:true
    }
  },
  {
    path: '/site/:siteId/account/favorites',
    name: 'My Favorites',
    component:() => import('@/views/Account/Favorites.vue'),
    meta:{
      blockSetPage: true,
      moduleKey:"app.account.favorites",
      transitionName:"slideV",
      depth:110,
      showBack:true,
      authPage:true,
      noMoreOpen:true
    }
  },

  {
    path: '/site/:siteId/account/order/:orderId/receipt',
    name: 'Order Receipt',
    component:() => import('@/views/Account/OrderReceipt.vue'),
    meta:{
      moduleKey:"app.account.orders",
      transitionName:"slide",
      depth:120,
      blockSetPage:true,
      showBack:true,
      authPage:true
    }
  },
  {
    path: '/site/:siteId/account/order/:orderId/aloha',
    name: 'Order Receipt Aloha',
    component:() => import('@/views/Account/OrderAloha.vue'),
    meta:{
      moduleKey:"app.account.orders",
      transitionName:"slide",
      depth:120,
      blockSetPage:true,
      showBack:true,
      authPage:true
    }
  },
  {
    path: '/site/:siteId/account/order/:orderId',
    name: 'Order',
    component:() => import('@/views/Account/Order.vue'),
    meta:{
      moduleKey:"app.account.orders",
      transitionName:"slide",
      depth:120,
      blockSetPage:true,
      showBack:true,
      authPage:true
    }
  },

  {
    path: '/site/:siteId/order/:orderId/split-bill',
    name: 'SplitBill',
    component:() => import('@/views/Order/SplitBill.vue'),
    meta:{
      transitionName:"slideV",
      depth:130,
      blockSetPage:true,
      showBack:true,
      authPage:true
    }
  },
  {
    path: '/site/:siteId/account/tncs/:order?',
    name: 'terms & Conditions',
    component:() => import('@/views/Account/TnCs.vue'),
    meta:{
      moduleKey:"app.account",
      tncPage:true,
      showBack:true,
      authPage:true,
      noMoreOpen:true,
      blockSetPage:true
    }
  },
  {
    path: '/site/:siteId/allergen-disclaimer',
    name: 'Allergen Disclaimer',
    component:() => import('@/views/Account/AllergenDisclaimer.vue'),
    meta:{
      blockSetPage: true,
      showBack:true,
    }
  },
  {
    path: '/site/:siteId/pre-home/:table?',
    name: 'PreHome',
    component:() => import('@/views/Pubs/PreHome.vue'),
    meta:{
      transitionName:"doors",
      depth:-2,
    }
  },
  {
    path: '/site/:siteId/home/:table?',
    name: 'Home',
    component:() => import('@/views/Pubs/Home.vue'),
    meta:{
      transitionName:"slideV",
      depth:-1,
    }
  },
  {
    path: '/site/:siteId/directions',
    name: 'Directions',
    component:() => import('@/views/Pubs/Directions.vue'),
    meta:{
      moduleKey:"app.directions",
      transitionName:"slideV",
      depth:10,
      showBack:true,
      blockSetPage: true
    }
  },
  {
    path: '/site/:siteId/site-info/:siteInfoId',
    name: 'Information',
    component:() => import('@/views/Pubs/Info.vue'),
    meta:{
      moduleKey:"app.siteInfo",
      transitionName:"slideV",
      depth:50,
      showBack:true,
      blockSetPage: true,
      manualAnalytics:true
    }
  },
  {
    path: '/site/:siteId/gallery',
    name: 'Gallery',
    component:() => import('@/views/Pubs/Gallery.vue'),
    meta:{
      blockSetPage: true,
      moduleKey:"app.gallery",
      transitionName:"slide",
      depth:50,
    }
  },
  {
    path: '/site/:siteId/news',
    name: 'News',
    component:() => import('@/views/Pubs/News.vue'),
    meta:{
      blockSetPage: true,
      moduleKey:"app.news",
      transitionName:"slideV",
      depth:50,
    }
  },
  {
    path: '/site/:siteId/news/:newsId',
    name: 'News Article',
    component:() => import('@/views/Pubs/NewsArticle.vue'),
    meta:{
      moduleKey:"app.news",
      transitionName:"slide",
      depth:60,
      blockSetPage: true,
      showBack:true,
      manualAnalytics:true
    }
  },
  {
    path: '/site/:siteId/taxis',
    name: 'Taxis',
    component:() => import('@/views/Pubs/Taxis.vue'),
    meta:{
      blockSetPage: true,
      moduleKey:"app.taxi",
      transitionName:"slideV",
      depth:60,
    }
  },
  {
    path: '/site/:siteId/contact',
    name: 'Contact',
    component:() => import('@/views/App/iframe.vue'),
    meta:{
      blockSetPage: true,
      transitionName:"slideV",
      depth:60,
    }
  },
  {
    path: '/site/:siteId/book-a-table',
    name: 'Book a Table',
    component:() => import('@/views/App/iframe.vue'),
    meta:{
      moduleKey:"app.booking",
      transitionName:"slideV",
      depth:50,
      showBack: true,
      blockSetPage: true

    }
  },
  {
    path: '/site/:siteId/feedback-it-back',
    name: 'FeedItBack',
    component:() => import('@/views/App/iframe.vue'),
    meta:{
      moduleKey:"app.feedback",
      transitionName:"slideV",
      depth:50,
      showBack: true,
      blockSetPage: true
    }
  },
  {
    path: '/site/:siteId/feedback/:resourceType?/:resourceUuid?',
    name: 'Feedback',
    component:() => import('@/views/Feedback/Feedback.vue'),
    meta:{
      moduleKey:"app.feedback",
      transitionName:"slideV",
      depth:50,
      showBack: true,
      blockSetPage: true
    }
  },
  {
    path: '/site/:siteId/complaints',
    name: 'Complaints',
    component:() => import('@/views/Feedback/Complaints.vue'),
    meta:{
      moduleKey:"app.feedback",
      transitionName:"slideV",
      depth:50,
      showBack: false,
      blockSetPage: true,
      hideBasket:true
    }
  },
  {
    path: '/site/:siteId/help',
    name: 'Help',
    component:() => import('@/views/App/iframe.vue'),
    meta:{
      moduleKey:"app.zendDesk.guide",
      transitionName:"slideV",
      depth:50,
      showBack: true,
      blockSetPage: true
    }
  },
  {
    path: '/site/:siteId/team',
    name: 'Team',
    component:() => import('@/views/App/iframe.vue'),
    meta:{
      moduleKey:"app.team",
      transitionName:"slideV",
      depth:50,
      showBack: true,
      blockSetPage: true
    }
  },
  {

    path: '/site/:siteId/menu/search',
    name: 'Search',
    component:() => import('@/views/Menu/Search.vue'),
    meta:{
      moduleKey:"app.menu.search",
      transitionName:"slideV",
      depth:10,

    }
  },
  {
    path: '/site/:siteId/menu/:type',
    name: 'Menus',
    component:() => import('@/views/Menu/Menus.vue'),
    meta:{
      moduleKey:"app.menu",
      transitionName:"slideV",
      depth:30,
      menuPage:true,
      submenuPage:true,
      manualAnalytics:true
    }
  },
  {
    path: '/site/:siteId/new-menu-products',
    name: 'New Menu Items',
    component:() => import('@/views/Menu/NewMenuProducts.vue'),
    meta:{
      moduleKey:"app.menu",
      transitionName:"slide",
      depth:40,
      menuPage:true,
      showBack:true,
      manualAnalytics:true,
      isNewProductsPage:true
    }
  },

  {
    path: '/site/:siteId/menu/:menuId/menu-products',
    name: 'Menu Items',
    component:() => import('@/views/Menu/Menu.vue'),
    meta:{
      moduleKey:"app.menu",
      transitionName:"slide",
      depth:40,
      menuPage:true,
      showBack:true,
      manualAnalytics:true
    }
  },
  {
    path: '/site/:siteId/menu/:menuId/menu-product/:menuProductId/:uuid?',
    name: 'Menu Product',
    component:() => import('@/views/Menu/MenuProduct.vue'),
    meta:{
      moduleKey:"app.menu",
      isCheckout:true,
      transitionName:"slide",
      depth:50,
      blockSetPage:true,
      menuPage:true,
      showBack:true,
      noMoreOpen:true,
      manualAnalytics:true,
      isProductPage:true
    }
  },
  {
    path: '/site/:siteId/menu-item/:menuItemId/:uuid/:edit?',
    name: 'Menu Item',
    component:() => import('@/views/Menu/MenuItem.vue'),
    meta:{
      moduleKey:"app.menu",
      transitionName:"slide",
      depth:60,
      blockSetPage:true,
      modPage:true,
      showBack:true,
      noMoreOpen:true
    }
  },
  {
    path: '/site/:siteId/nutritional-info/:menuProductId/:menuItemId/:modifierId?',
    name: 'Nutritional Info',
    component:() => import('@/views/Menu/NutritionalInfo.vue'),
    meta:{
      moduleKey:"app.menu",
      depth:100,
      blockSetPage:true,
      showBack:true,
      manualAnalytics:true
    }
  },


  {
    path: '/site/:siteId/basket',
    name: 'Basket',
    component:() => import('@/views/Order/Basket.vue'),
    meta:{
      moduleKey:"app.menu",
      isCheckout:true,
      transitionName:"slideV",
      depth:100,
      //blockSetPage:true,
      showBack:true,
      noMoreOpen:true

    }
  },
  {
    path: '/site/:siteId/coursing',
    name: 'Coursing',
    component:() => import('@/views/Order/Coursing.vue'),
    meta:{
      moduleKey:"app.menu.coursing",
      isCheckout:true,
      transitionName:"slide",
      depth:110,
      blockSetPage:true,
      showBack:true
    }
  },

  {
    path: '/site/:siteId/giftcards/:redemption_code?',
    name: 'GiftCards',
    component:() => import('@/views/Giftcards/Giftcards.vue'),
    meta:{
      moduleKey:"app.giftcards",
      isCheckout:true,
      transitionName:"slide",
      depth:110,
      blockSetPage:true,
      showBack:true,
      noGeo:true
    }
  },

  {
    path: '/site/:siteId/select-table',
    name: 'Select Table',
    component:() => import('@/views/Order/SelectTable.vue'),
    meta:{
      moduleKey:"app.menu",
      isCheckout:true,
      transitionName:"slide",
      depth:120,
      blockSetPage:true,
      showBack:true
    }
  },
  {
    path: '/site/:siteId/guest-checkout/:check?',
    name: 'Guest Checkout',
    component:() => import('@/views/Order/GuestCheckout.vue'),
    meta:{
      moduleKey:"app.menu",
      isCheckout:true,
      transitionName:"slide",
      depth:190,
      blockSetPage:true,
      showBack:true
    }
  },
  {
    path: '/site/:siteId/summary',
    name: 'Summary',
    component:() => import('@/views/Order/Summary.vue'),
    meta:{
      moduleKey:"app.menu",
      isCheckout:true,
      transitionName:"slide",
      depth:500,
      blockSetPage:true,
      showBack:true
    }
  },
  {

    path: '/site/:siteId/payment/:uuid',
    name: 'Payment',
    component:() => import('@/views/Order/Payment.vue'),
    meta:{
      moduleKey:"app.menu",
      isCheckout:true,
      transitionName:"slide",
      depth:510,
      blockSetPage:true,
      hideFooter:true,
      noMoreOpen:true
    }
  },
  {
    path: '/site/:siteId/ticket/:uuid/success',
    name: 'OrderSuccess',
    component:() => import('@/views/Order/Success.vue'),
    meta:{
      moduleKey:"app.menu",
      isCheckout:true,
      transitionName:"slide",
      depth:600,
      blockSetPage:true
    }
  },


  {
    path: '/site/:siteId/opentables/:checkId',
    name: 'OpenTables',
    component:() => import('@/views/OpenTables/OpenTables.vue'),
    meta:{
      moduleKey:"app.opentables",
      isCheckout:true,
      transitionName:"slide",
      depth:490,
      blockSetPage:true,
      showBack: true
    }
  },

  {
    path: '/site/:siteId/table',
    name: 'OpenTablesTable',
    component:() => import('@/views/Checks/Table.vue'),
    meta:{
      moduleKey:"app.paybill",
      isCheckout:true,
      transitionName:"slide",
      depth:160,
      blockSetPage:true
    }
  },
  {
    path: '/site/:siteId/table/:tableId/select/:opentables?',
    name: 'OpenTablesSelect',
    component:() => import('@/views/Checks/Select.vue'),
    meta:{
      moduleKey:"app.paybill",
      isCheckout:true,
      transitionName:"slide",
      depth:170,
      blockSetPage:true,
      showBack: true
    }
  },
  {
    path: '/site/:siteId/table/:tableId/check/:checkId',
    name: 'OpenTablesView',
    component:() => import('@/views/Checks/View.vue'),
    meta:{
      moduleKey:"app.paybill",
      isCheckout:true,
      transitionName:"slide",
      depth:180,
      blockSetPage:true,
      showBack: true
    }
  },
  {
    path: '/site/:siteId/table/:tableId/check/:checkId/split/:uuid',
    name: 'OpenTablesSplit',
    component:() => import('@/views/Checks/Split.vue'),
    meta:{
      moduleKey:"app.paybill",
      isCheckout:true,
      transitionName:"slide",
      depth:190,
      blockSetPage:true,
      showBack: true
    }
  },
  {
    path: '/site/:siteId/check/:uuid/tip',
    name: 'OpenTablesTip',
    component:() => import('@/views/Checks/Tip.vue'),
    meta:{
      moduleKey:"app.paybill",
      isCheckout:true,
      transitionName:"slide",
      depth:200,
      blockSetPage:true,
      showBack: true
    }
  },
  {
    path: '/site/:siteId/check/:uuid/summary',
    name: 'OpenTablesSummary',
    component:() => import('@/views/Checks/Summary.vue'),
    meta:{
      moduleKey:"app.paybill",
      isCheckout:true,
      transitionName:"slide",
      depth:210,
      blockSetPage:true,
      showBack: true
    }
  },

  {
    path: '/site/:siteId/check/:uuid/success',
    name: 'OpenTablesSuccess',
    component:() => import('@/views/Checks/Success.vue'),
    meta:{
      moduleKey:"app.paybill",
      isCheckout:true,
      transitionName:"slide",
      depth:510,
      blockSetPage:true,
      showBack: false
    }
  },

 /* {
    path: '/image/:imageId/:uuid?',
    name: 'Image',
    component:() => import('@/components/App/Image.vue'),
    meta:{
      hideFooter:true,
      blockSetPage:true,
      showBack:true
    }
  },*/
  {
    path: '/site/:siteId/maintenance',
    name: 'Maintenance',
    component:() => import('@/views/App/Maintenance.vue'),
    meta:{
      hideFooter:true,
      blockSetPage:true,
      maintenancePage:true
    }
  },
  {
    path: '/site/:siteId/blocked',
    name: 'Blocked',
    component:() => import('@/views/App/Blocked.vue'),
    meta:{
      hideFooter:true,
      blockSetPage:true,
      blockedPage:true,
      authPage:true
    }
  },
  {
    path: '/site/:siteId/offline',
    name: 'Offline',
    component:() => import('@/views/App/Offline.vue'),
    meta:{
      hideFooter:true,
      blockSetPage:true,
      blockedPage:true
    }
  },
  {
    path: '/access/:uuid/:edit?',
    name: 'Access',
    component:() => import('@/views/App/Access.vue'),
    meta:{
      hideFooter   : true,
      blockSetPage : true,
      blockedPage  : true,
      noGeo:true
    }
  },
  {
    path: '/site/:siteId/discounts/generate',
    name: 'DiscountGenerate',
    component:() => import('@/views/Discounts/Generate.vue'),
    meta:{
      moduleKey    : 'app.tickets.discounts',
      //hideFooter   : true,
      blockSetPage : true,
      showBack     : true,
    }
  },
  {
    path: '/site/:siteId/management/wait-time',
    name: 'ManagementWaitTime',
    component:() => import('@/views/Management/WaitTime.vue'),
    meta:{
      //hideFooter   : true,
      blockSetPage : true,
      showBack     : true,
    }
  },
  {
    path: '/site/:siteId/management/payments',
    name: 'ManagementPayments',
    component:() => import('@/views/Management/Payments.vue'),
    meta:{
      //hideFooter   : true,
      blockSetPage : true,
      showBack     : true,
    }
  },
  {
    path: '/site/:siteId/management/1-2-1-items',
    name: 'Management121Items',
    component:() => import('@/views/Management/One2OneItems.vue'),
    meta:{
      //hideFooter   : true,
      blockSetPage : true,
      showBack     : true,
    }
  },
  {
    path: '/site/:siteId/management/1-2-1/home',
    name: 'Management121Home',
    component:() => import('@/views/Management/One2OneHome.vue'),
    meta:{
      //hideFooter   : true,
      blockSetPage : true,
      showBack     : true,
    }
  },
  {
    path: '/site/:siteId/management/1-2-1-group-items',
    name: 'Management121GroupItems',
    component:() => import('@/views/Management/One2OneGroupItems.vue'),
    meta:{
      //hideFooter   : true,
      blockSetPage : true,
      showBack     : true,
    }
  },
  {
    path: '/site/:siteId/management/1-2-1-group-items/create/:itemId?',
    name: 'Management121GroupItemsCreate',
    component:() => import('@/views/Management/One2OneGroupItemsCreate.vue'),
    meta:{
      //hideFooter   : true,
      blockSetPage : true,
      showBack     : true,
    }
  },
  {
    path: '/site/:siteId/management/team-products/home',
    name: 'ManagementTeamProducts',
    component:() => import('@/views/Management/TeamProductsHome.vue'),
    meta:{
      //hideFooter   : true,
      blockSetPage : true,
      showBack     : true,
    }
  },
  {
    path: '/site/:siteId/management/team-products/order',
    name: 'ManagementTeamProductsPrep',
    component:() => import('@/views/Management/TeamProductsOrder.vue'),
    meta:{
      //hideFooter   : true,
      blockSetPage : true,
      showBack     : true,
    }
  },

  {
    path: '/site/:siteId/management/team-products/prepare',
    name: 'ManagementTeamProductsPrepare',
    component:() => import('@/views/Management/TeamProductsPrepare.vue'),
    meta:{
      //hideFooter   : true,
      blockSetPage : true,
      showBack     : true,
    }
  },
  {
    path: '/site/:siteId/management/team-products/receive',
    name: 'ManagementTeamProductsReceive',
    component:() => import('@/views/Management/TeamProductsReceive.vue'),
    meta:{
      //hideFooter   : true,
      blockSetPage : true,
      showBack     : true,
    }
  },
  {
    path: '/site/:siteId/management/hosts-tables',
    name: 'ManagementHostsTables',
    component:() => import('@/views/Management/HostsTables.vue'),
    meta:{
      //hideFooter   : true,
      blockSetPage : true,
      showBack     : true,
    }
  },
  {
    path: '/site/:siteId/management/assign-host-to-tables/:hostTablesId?',
    name: 'ManagementAssignHostsToTables',
    component:() => import('@/views/Management/AssignHostsToTables.vue'),
    meta:{
      //hideFooter   : true,
      depth: 160,
      blockSetPage : true,
      showBack     : true,
    }
  },
  {
    path: '/site/:siteId/management/packages',
    name: 'ManagementPackages',
    component:() => import('@/views/Management/Packages.vue'),
    meta:{
      moduleKey    : 'app.booking.preorder',
      //hideFooter   : true,
      blockSetPage : true,
      showBack     : true,
    }
  },
  {
    path: '/site/:siteId/management/refunds',
    name: 'RefundItems',
    component:() => import('@/views/Management/Refunds.vue'),
    meta:{
      moduleKey    : 'app.booking.preorder',
      //hideFooter   : true,
      blockSetPage : true,
      showBack     : true,
    }
  },


  {
    path: '/site/:siteId/management/hub/prep',
    name: 'HubPrep',
    component:() => import('@/views/Management/HubPrep.vue'),
    meta:{
      moduleKey    : 'app.booking.preorder',
      //hideFooter   : true,
      blockSetPage : true,
      showBack     : true,
    }
  },
  {
    path: '/site/:siteId/management/pub/receive',
    name: 'PubReceive',
    component:() => import('@/views/Management/PubReceive.vue'),
    meta:{
      moduleKey    : 'app.booking.preorder',
      //hideFooter   : true,
      blockSetPage : true,
      showBack     : true,
    }
  },



  {
    path: '/site/:siteId/management/activate-gift-card',
    name: 'ActivateGiftCard',
    component:() => import('@/views/Giftcards/Activate.vue'),
    meta:{
      //moduleKey    : 'app.appytap',
      //hideFooter   : true,
      blockSetPage : true,
      showBack     : true,
    }
  },

  {
    path: '/site/:siteId/appytap/team',
    name: 'AppyTapTeamAction',
    component:() => import('@/views/AppyTap/Team.vue'),
    meta:{
      moduleKey    : 'app.appytap',
      //hideFooter   : true,
      blockSetPage : true,
      showBack     : true,
      noGeo:true
    }
  },

  {
    path: '/site/:siteId/appytap/:shortcut/action/:nonce',
    name: 'AppyTapAction',
    component:() => import('@/views/AppyTap/TapAction.vue'),
    meta:{
      moduleKey    : 'app.appytap',
      //hideFooter   : true,
      blockSetPage : true,
      showBack     : true,
      noGeo        : true
    }
  },

  {
    path: '/site/:siteId/management',
    name: 'Management',
    component:() => import('@/views/Management/Management.vue'),
    meta:{
      moduleKey:"app.account",
      transitionName:"slide",
      depth:120,
      blockSetPage:true,
      showBack:true
    }
  },

  {
    path: '/site/:siteId/management/team-host-tables',
    name: 'ManagementTeamHostTables',
    component:() => import('@/views/Management/TeamHostTables.vue'),
    meta:{
      moduleKey:"app.account",
      transitionName:"slide",
      depth:120,
      blockSetPage:true,
      showBack:true
    }
  },

  {
    path: '/site/:siteId/booking/home',
    name: 'BookingHome',
    component: () => import('../views/Booking/BookingHome.vue'),
    meta: {
      transitionName: 'slideV',
      depth:10,
      showBack: true,
      isDiary:true,
      blockSetPage:true
    }
  },


  {
    path: '/site/:siteId/booking/edit/:reference?',
    name: 'BookingEdit',
    component: () => import('../views/Booking/BookingEdit.vue'),
    meta: {
      transitionName: 'slideV',
      depth:10,
      showBack: true,
      isDiary:true,
      blockSetPage:true
    }
  },


  {
    path: '/site/:siteId/booking/edit-items/:reference?',
    name: 'BookingEditItems',
    component: () => import('../views/Booking/BookingEdit.vue'),
    meta: {
      transitionName: 'slideV',
      depth:10,
      showBack: true,
      isDiary:true,
      blockSetPage:true
    }
  },


  {
    path: '/site/:siteId/booking/calendar',
    name: 'Calendar',
    component: () => import('../views/Booking/Calendar.vue'),
    meta: {
      transitionName: 'slideV',
      depth:20,
      showBack: true,
      isDiary:true,
      blockSetPage:true
    }
  },
  {
    path: '/site/:siteId/booking/promotions/:promotionIds',
    name: 'Promotions',
    component: () => import('../views/Booking/Promotions.vue'),
    meta: {
      transitionName:"slide",
      depth:21,
      showBack: true,
      isDiary:true,
      blockSetPage:true
    }
  },

  {
    path: '/site/:siteId/booking/guest',
    name: 'Guest',
    component: () => import('../views/Booking/Guest.vue'),
    meta: {
      transitionName:"slide",
      depth:30,
      showBack: true,
      isDiary:true,
      blockSetPage:true
    }
  },
  {
    path: '/site/:siteId/booking/:bookingUuid/package/categories',
    name: 'PackageCategories',
    component: () => import('../views/Booking/PackageCategories.vue'),
    meta: {
      transitionName:"slide",
      depth:31,
      showBack: true,
      isDiary:true,
      blockSetPage:true
    }
  },

  {
    path: '/site/:siteId/booking/:bookingUuid/package/category/:packageCategoryId',
    name: 'Packages',
    component: () => import('../views/Booking/Packages.vue'),
    meta: {
      transitionName:"slide",
      depth:32,
      showBack: true,
      isDiary:true,
      blockSetPage:true
    }
  },

  {
    path: '/site/:siteId/booking/:bookingUuid/basket',
    name: 'BookingBasket',
    component: () => import('../views/Booking/BookingBasket.vue'),
    meta: {
      transitionName:"slide",
      depth:55,
      showBack: true,
      isDiary:true,
      blockSetPage:true
    }
  },
  {
    path: '/site/:siteId/booking/:bookingUuid/summary',
    name: 'BookingSummary',
    component: () => import('../views/Booking/Summary.vue'),
    meta: {
      transitionName:"slide",
      depth:60,
      showBack: true,
      isDiary:true,
      blockSetPage:true
    }
  },
  {
    path: '/site/:siteId/booking/:bookingUuid/success',
    name: 'BookingSuccess',
    component: () => import('../views/Booking/Success.vue'),
    meta: {
      depth:70,
      transitionName: 'slideV',
      showBack: false,
      isDiary:true,
      blockSetPage:true
    }
  },


  {
    path: '/site/:siteId/booking/cancel/:reference?',
    name: 'BookingCancel',
    component: () => import('../views/Booking/Cancel.vue'),
    meta: {
      depth:1000,
      transitionName: 'slideV',
      showBack: true,
      isDiary:true,
      blockSetPage:true
    }
  },
  {
    path: "*",
    name:"404",
    component: () => import('@/views/App/Intro.vue'),
    meta: {
      blockSetPage:true
    }
  }
]

const router = new VueRouter({
  base: '/',
  mode: 'history',//mode: 'hash',
  //base: process.env.BASE_URL,
  routes
})



/// CHECKS FOR PUBLIC PAGE SET OR NOT
router.beforeEach((to, from, next) => {

  //console.log(to.name,to.meta.moduleKey,store.getters.moduleActive(to.meta.moduleKey))

  CDV.tap('medium');

  if(to.meta.moduleKey!==undefined && store.getters.moduleActive(to.meta.moduleKey)!==true) next("/not-found");

  if(window.zE && window.zE.hide) window.zE.hide();

  const main = document.querySelector('main');
  if(main) store.commit('setLastPagePosition',{path:from.path,scrollPosition:main.scrollTop });

  const goingBack         = (to.meta.depth < from.meta.depth);

  let newTransitionName = "fade";
  const transitionName    = (goingBack)?from.meta.transitionName:to.meta.transitionName;

  if (transitionName === 'slide')  newTransitionName = goingBack ? 'slide-right' : 'slide-left';
  if (transitionName === 'slideV') newTransitionName = goingBack ? 'slide-down' : 'slide-up';

  if(from.name==='PreHome') { newTransitionName = 'doors' }

  if(window.popStateDetected===true) {
    window.doLastPageScroll = true;
    if(store.getters.goingBack!==true) newTransitionName = 'none';
  }

  store.commit('setTransitionName',newTransitionName);

  next();

});

let timeo = null;

router.afterEach((to) => {
  if(to.meta.blockSetPage!==true) store.dispatch("setPage",to.path);
   clearTimeout(timeo);
   timeo = setTimeout(()=>{
        store.commit('goingBack',false);
        store.commit('removeLastPagePosition',to);
        window.doLastPageScroll = false;
        window.popStateDetected = false
   },500);

   const name = (store.getters.site)?store.getters.site.code.toUpperCase():'';
   if(to.meta.manualAnalytics!==true) CDV.setCurrentScreen(name+' '+to.name);


   // if(window.ga)window.ga.trackView(to.name,to.path);
    if(!window.cordova &&  Vue.$gtag){
      Vue.$gtag.pageview({
        page_path   : to.path,
        page_title  : to.name,
      })
    }

});

router.onError(error => {
  if (/loading chunk \d* failed./i.test(error.message)) {
    window.location.reload()
  }
})

export default router
